import type { FC } from "react";
import React from "react";
import { ArrowLeftIcon } from "src/constants/icons";
import styled from "@emotion/styled";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { createPortal } from "react-dom";

const Button = styled.button`
  label: OverlayBackButton;
  position: sticky;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
  color: var(--color-charcoal);
  pointer-events: all;
  width: 3rem;
  aspect-ratio: 1;
  transform: translateY(-30%) translateX(-30%);
  background: transparent;

  svg {
    width: 1.8rem;
    height: auto;
  }

  @media (min-width: 730px) {
    top: calc(64px + 0.65rem);
  }
`;

const OverlayBackButton: FC<{
  fallbackUrl?: string;
  alwaysUseFallback?: boolean;
  show?: boolean;
}> = ({ fallbackUrl, show }) => {
  const navigate = useGoToOrBack();
  const navigateUrl = fallbackUrl ?? "/app";

  const handleBack = () => {
    navigate(navigateUrl, {
      useBack: false
    });
  };

  if (!show) return null;

  try {
    return createPortal(
      <Button onClick={handleBack}>
        <ArrowLeftIcon />
      </Button>,
      document.getElementById("backButtonPortalTarget") as Element
    );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return null;
  }
};

export default OverlayBackButton;
